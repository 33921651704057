.custom_input_checkout {
  flex: 1;
  height: 45px;
  position: relative;

  &:hover {
    border-color: black;
  }

  .custom_input_field {
    border: 1px solid #cdcdcd;
    width: 100%;
    height: 100%;
    padding: 10px;
    outline: none;
    transition: .1s ease-in;
    border-radius: 5px;

    &.err_field {
      border: 1px solid red !important;
    }

    &:focus {
      border-color: #1e72bd;
    }
  }

  .custom_input_label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    padding: 0 5px;
    transition: .1s ease-in;
    pointer-events: none;
  }

  .custom_input_field:focus ~ .custom_input_label,
  .custom_input_field:not(:placeholder-shown).custom_input_field:not(:focus) ~ .custom_input_label {
    top: 0;
    font-size: 13px;
    color: #1e72bd;
    background-color: white;
  }
}